/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

// react-router components
import { Link } from "react-router-dom";

// sweetalert2 components
import Swal from "sweetalert2";

// @mui material components
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftDropzone from "components/SoftDropzone";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";

// Vocality components
import VocalitySlider from "layouts/dashboards/components/VocalitySlider";
import InfoButton from "layouts/dashboards/components/InfoButton";
import AudioPlayer from "layouts/dashboards/components/AudioPlayer"

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Loading from "examples/Animations/Loading";

// Utilities
import { getAudioSamplePath } from "utils/files";

// Hooks & queries & constants
import { REQUESTSTATE } from "constants/request";
import { useGetProductsQuery } from "services/product";
import { useGetVoicesQuery } from "services/voice";
import { useCreateInferenceRequestMutation, useGetInferenceByInferenceIdQuery, useGetAudioMediaLinkByRequestIdQuery } from "services/inference";

const emptySample = {
  title: "",
  server: "cloud",
  product: "",
  language: "",
  speaker: "",
  frequency: "44100",
  speed: 1,
  // pause accordion
  periodDur: 1.5,
  ellipsisDur: 0.75,
  semicolonDur: 0.75,
  colonDur: 0.75,
  exclamationDur: 1,
  interrogationDur: 1,
  //----------------
  processingText: "",
  cloningText: ""
};

function Default() {

  //Set last visited view
  localStorage.setItem("last_visited", "/generate");

  // useStates
  const [requestID, setRequestID] = useState("");
  const [config, setConfig] = useState(emptySample);
  const [speakers, setSpeakers] = useState([]);
  const [speakerPath, setSpeakerPath] = useState("");
  const [dictionary, setDictionary] = useState([]);
  const [dictionaryText, setDictionaryText] = useState("");
  const [intervalIsActive, setIntervalIsActive] = useState(false);
  const [mediaLink, setMediaLink] = useState(null);

  // Queries
  const { data:products, isError, isLoading } = useGetProductsQuery(); // Fetch products from stripe
  const [ requestAudioInference, {data:inferenceData, status:inferenceStatus, isError:inferenceIsError, isLoading:inferenceIsLoading, isSuccess:inferenceIsSuccess, reset:resetInference} ] = useCreateInferenceRequestMutation(); // Create inference request
  const { data:generatedVoiceData, isError:generatedVoiceIsError, isLoading:generatedVoiceIsLoading, status:generatedVoiceStatus, refetch:generatedVoiceRefetch } = useGetInferenceByInferenceIdQuery(requestID);

  //User
  const user = useSelector((state) => state.auth.userProfile);
  
  // useEffects
  useEffect(() => {
    let id;
    if (intervalIsActive) {
      id = setInterval(() => {
        generatedVoiceRefetch();
      }, 3000);
    }

    return () => clearInterval(id);
  }, [intervalIsActive]);

  useEffect(() => {
    if (generatedVoiceData){
      switch(generatedVoiceData.status) {
        case REQUESTSTATE.COMPLETED:
          setMediaLink(generatedVoiceData.media_link)
          break;
        case REQUESTSTATE.FAILED:
          resetDashboard();
          Swal.fire("¡Vaya!", "Ha habido un problema generando tu voz.", "error");
          break;
        default:
      }
    }
  }, [generatedVoiceData]);

  useEffect(() => {
    if (inferenceIsSuccess){
      setRequestID(inferenceData.request_id)
      setIntervalIsActive(true);
    } 
  }, [inferenceIsSuccess]);

  // Setting voices data
  const { data:servspeakers, refetch:refetchSpeakers } = useGetVoicesQuery();

  function createSpeakerArray(lang) {
    var filteredData = servspeakers.filter(function (el) {
      return el.language == lang.value});
    var voiceNames = filteredData.map((item) => ({ value: item.name, label: item.title })).sort((a, b) => a.label.localeCompare(b.label));
    return voiceNames;
  }
  function createOptions(val) {
    return { value: val, label: val };
  }
  const languages = servspeakers ? [...new Set(servspeakers.map((item) => item.language))] : [];
  const languagesOptions = servspeakers ? languages.map(createOptions) : [];
  const allSpeakers = servspeakers ? languagesOptions.map(createSpeakerArray) : [];

  // Handlers---------------------------------------------------------

  const handleTitleChange = (e) =>
    setConfig({ ...config, title: e.target.value });
  const handleServerChange = (e) =>
    setConfig({ ...config, server: e.target.value });
  const handleProductChange = (inputValue, { action, prevInputValue }) => {
    setConfig({ ...config, product: inputValue.value});
  }
  const handleLanguageChange = (inputValue, { action, prevInputValue }) => {
    setSpeakers(allSpeakers[languages.indexOf(inputValue.value)]);
    setConfig({ ...config, language: inputValue.value, speaker: "" })
  };
  const handleSpeakerChange = (inputValue, { action, prevInputValue }) =>{
    var aplayer = document.getElementById('voicePlayer');
    if (aplayer){
      aplayer.load();
    }
    // setSpeakerPath("/files/" + inputValue.value.replace(" ", "") + ".mp3");
    setSpeakerPath(getAudioSamplePath(servspeakers, inputValue.value));
    setConfig({ ...config, speaker: {value: inputValue.value, label: inputValue.label} });
  }
  const handleFrequencyChange = (e) =>
    setConfig({ ...config, frequency: e.target.value });
  const handleSpeedChange = (e) =>
    setConfig({ ...config, speed: e.target.value });
  //-------------------
  const handlePeriodDurChange = (e) =>
    setConfig({ ...config, periodDur: e.target.value });
  const handleEllipsisDurChange = (e) =>
    setConfig({ ...config, ellipsisDur: e.target.value });
  const handleSemicolonDurChange = (e) =>
    setConfig({ ...config, semicolonDur: e.target.value });
  const handleColonDurChange = (e) =>
    setConfig({ ...config, colonDur: e.target.value });
  const handleExclamationDurChange = (e) =>
    setConfig({ ...config, exclamationDur: e.target.value });
  const handleInterrogationDurChange = (e) =>
    setConfig({ ...config, interrogationDur: e.target.value });
  //----------------------
  const handleTextChange = (e) =>
    setConfig({ ...config, processingText: e.target.value });
  const handleTextDropped = (text) =>
    setConfig({ ...config, processingText: text });
  const handleFinalTextChange = (e) =>
    setConfig({ ...config, cloningText: e.target.value });

  // Dictionary handlers
  const handleAddDictionary = (dic) => {
    setDictionary(dic);
  }

  // Reset dashboard
  const resetDashboard = () => {
    setConfig(emptySample);
    setRequestID("");
    setIntervalIsActive(false);
    setMediaLink(null);
    resetInference();
  }

  // Submit
  const handleSubmit = async (event) => {

    event.preventDefault();

    // If important data for request is missing, we alert the user
    if(config.product == "" || config.language == "" || config.speaker == {} || config.cloningText == "" || config.title == ""){
      Swal.fire("¡Espera!", "Se necesita especificar el Título, el Tipo de producto, el Idioma, el Locutor, y tener un fragmento de texto procesado para poder crear tu voz.", "warning");
    } else {
      requestAudioInference({
        title: config.title,
        text: config.cloningText,
        product_id: config.product,
        voice: config.speaker.value,
        language: config.language,
        frequency: parseInt(config.frequency),
        speed: config.speed,
        semicolon_pause: Number(config.semicolonDur),
        ellipsis_pause: Number(config.ellipsisDur),
        double_point_pause: Number(config.colonDur),
        question_mark_pause: Number(config.interrogationDur),
        exclamation_mark_pause: Number(config.exclamationDur),
        period_pause: Number(config.periodDur),
        hardware_used: config.server
      });
    }

  };
  //-------------------------------------------------------------------------
  // Process text
  const processText = (event) => {
    // VARIABLES
    var transformText = config.processingText;
    // aux text strings to not replace final transformed text
    var headTransText = ""
    var tailTransText = transformText
    // length of word -> useful for replacing word with phonetic pronountiation
    var wordlen = ""
    // variable resulting from checking if there is a match in the text with the word
    var regexparray = ""
    // substring which contains word + character right before it + character right after it; if the word must be whole we check it using this
    var auxsubstring = ""
    // auxiliary variable in order to not directly use dictionary word
    var auxword = ""
    // we compare this to the matching substring. Important for case sensitivenss
    var pureAuxWord = ""
    // removal of .!? characters existing in word["Palabra"] to not confuse the code
    var temporaryperiodremove = ""
    // text which appears in error notif (if there is one)
    var processErrorText = "";

    //ORDER DICTIONARY BY LENGTH OF WORDS, case sensitiveness and whether it's a whole word or not
    dictionary.sort((a,b) => b["Palabra entera"] - a["Palabra entera"]);
    dictionary.sort((a,b) => b["Palabra"].length - a["Palabra"].length);
    dictionary.sort((a,b) => b["Distingue capitalización"] - a["Distingue capitalización"]);

    //REPLACING TEXT TO PHONETIC SOUND
    dictionary.forEach((word) => {
      auxword = word["Palabra"];
      wordlen = auxword.length;
      headTransText = ""
      tailTransText = transformText;

      if (word["Distingue capitalización"] == false){
        auxword = auxword.toLowerCase();
        tailTransText = tailTransText.toLowerCase();
      }
      else if (word["Distingue capitalización"] != true){
        processErrorText = 'Existen filas con un valor ambiguo en la columna "Distingue capitalización"'
      }

      pureAuxWord = auxword;

      if (auxword.search(/[\[\]*+|{}\\()@\n\r]/) != -1){
        auxword = auxword.replace(/[\[\]*+|{}\\()@\n\r]/g, "\\\$&");
      }

      regexparray = RegExp(auxword, 'g').exec(tailTransText)
      do {
        if (regexparray && processErrorText == ""){
          if (word["Palabra entera"] == true){


            //Periods, exclamation and question maerks can be at end of word we want to check as whole word
            temporaryperiodremove = auxword.replace(/\./g, "_TRANSFORMPERIOD_").replace(/\?/g, "_TRANSFORMQUESTION_").replace(/\!/g, "_TRANSFORMEXCLAMATION_")
            auxsubstring = tailTransText.substring(regexparray.index - 1, regexparray.index + wordlen + 1).replace(auxword, temporaryperiodremove)
            if(auxsubstring.charAt(auxsubstring.length - 1) == "." || auxsubstring.charAt(auxsubstring.length - 1) == "!" || auxsubstring.charAt(auxsubstring.length - 1) == "?"){
              auxsubstring = auxsubstring.replace(/.$/," ")
            }

            if (auxsubstring.trim().replace(/_TRANSFORMPERIOD_/g, ".").replace(/_TRANSFORMQUESTION_/g, "?").replace(/_TRANSFORMEXCLAMATION_/g, "!") == pureAuxWord){
              headTransText = headTransText + tailTransText.substring(0, regexparray.index) + word["Fonética correcta"];
              tailTransText = tailTransText.substring(regexparray.index + wordlen, tailTransText.length)
            }
            else{
              headTransText = headTransText + tailTransText;
              tailTransText = "";
            }
          }
          else if(word["Palabra entera"] == false){

            auxsubstring = tailTransText.substring(regexparray.index, regexparray.index + wordlen)

            if (auxsubstring == pureAuxWord){
              headTransText = headTransText + tailTransText.substring(0, regexparray.index) + word["Fonética correcta"];
              tailTransText = tailTransText.substring(regexparray.index + wordlen, tailTransText.length)
            }
            else{
              headTransText = headTransText + tailTransText;
              tailTransText = "";
            }
          }
          else{
            processErrorText = 'Existen filas con un valor ambiguo en la columna "Palabra entera"'
          }
          //Set regexparray again for more loop iterations if word exists several times
          if (word["Distingue capitalización"] == false){
            auxword = auxword.toLowerCase();
            tailTransText = tailTransText.toLowerCase();
          }

          regexparray = RegExp(auxword, 'g').exec(tailTransText)
        }
      }
      while (regexparray && processErrorText == "");
      transformText = headTransText + tailTransText;
    });
    if (processErrorText == ""){
      setConfig({ ...config, cloningText: transformText.replace(/\s+/g, ' ') });
    }
    else{
      setConfig({ ...config, cloningText: "" });
      Swal.fire("¡Error!", processErrorText, "error");
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <Grid container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={5}>
              {/* CONFIGURATION CARD----------------------------------------------------------------------------- */}
              <Card sx={{ height: "100%" }}>
                <SoftBox padding="1rem">
                  <SoftBox mb={2}>
                    <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Título
                    </SoftTypography>
                    <SoftBox>
                      <SoftInput
                        id="title-text"
                        placeholder="Escriba el título de la locución"
                        value= {config.title}
                        onChange={handleTitleChange}/>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Opciones de hardware
                    </SoftTypography>
                    <SoftBox ml={2}>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          value={config.server}
                          onChange={handleServerChange}
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel value="GPU" control={<Radio />} label="GPU" />
                          <FormControlLabel value="CPU" control={<Radio />} label="CPU" />
                        </RadioGroup>
                      </FormControl>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Tipo de producto
                    </SoftTypography>
                    <SoftSelect
                      placeholder="Elige un tipo de producto"
                      value={config.product ? {value: config.product, label: products.filter((prod) => prod.id == config.product)[0].name } : null}
                      onChange={handleProductChange}
                      options={ products && products.map((product) => ({ value: product.id, label: product.name })) }
                    />
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Idioma
                    </SoftTypography>
                    <SoftSelect
                      placeholder="Elige un idioma"
                      value={config.language ? {value: config.language, label: config.language } : null}
                      onChange={handleLanguageChange}
                      options={languagesOptions}
                    />
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Locutor
                    </SoftTypography>
                    <SoftBox mb={2}>
                      <SoftSelect
                        placeholder="Elige un locutor"
                        value={config.speaker ? config.speaker : null}
                        onChange={handleSpeakerChange}
                        options={speakers}
                      />
                    </SoftBox>
                    {config.speaker && (
                      <AudioPlayer path={speakerPath}/>
                    )}

                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Frecuencia
                    </SoftTypography>
                    <SoftBox ml={2}>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          value={config.frequency}
                          onChange={handleFrequencyChange}
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel value="24000" control={<Radio />} label="24000" />
                          <FormControlLabel value="44100" control={<Radio />} label="44100" />
                          <FormControlLabel value="48000" control={<Radio />} label="48000" />
                        </RadioGroup>
                      </FormControl>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Velocidad
                      <InfoButton text="No disponible para modelo en euskera."/>
                    </SoftTypography>
                    <VocalitySlider min={0.5} marks value={config.speed} onChange={handleSpeedChange}/>
                  </SoftBox>
                  <SoftBox mb={2}>
                    {/* PAUSE DURATION ACCORDION */}
                    <Accordion>
                      <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      >
                      <SoftTypography variant="h6" fontWeight="medium">Duración pausas</SoftTypography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <VocalitySlider min={0} title="Duración punto" value={config.periodDur} onChange={handlePeriodDurChange}/>
                        <Divider />
                        <VocalitySlider min={0} title="Duración puntos suspensivos" value={config.ellipsisDur} onChange={handleEllipsisDurChange}/>
                        <Divider />
                        <VocalitySlider min={0} title="Duración punto y coma" value={config.semicolonDur} onChange={handleSemicolonDurChange}/>
                        <Divider />
                        <VocalitySlider min={0} title="Duración dos puntos" value={config.colonDur} onChange={handleColonDurChange}/>
                        <Divider />
                        <VocalitySlider min={0} title="Duración exclamación" value={config.exclamationDur} onChange={handleExclamationDurChange}/>
                        <Divider />
                        <VocalitySlider min={0} title="Duración interrogación" value={config.interrogationDur} onChange={handleInterrogationDurChange}/>
                      </AccordionDetails>
                    </Accordion>
                    {/* DICTIONARY ACCORDION */}
                    <Accordion>
                      <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                      >
                      <SoftTypography variant="h6" fontWeight="medium">
                        Diccionario
                      </SoftTypography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <SoftBox mb={2}>
                          <Link to="/files/diccionario.xlsx" target="_blank" download>
                            <SoftButton
                                variant="gradient"
                                color="secondary"
                              >
                              {"descargar diccionario"}
                            </SoftButton>
                          </Link>
                          <InfoButton modal text={`Suba un archivo en formato .csv o .xlsx que contenga cuatro columnas: <ul  style={{paddingLeft: 25}}>
                                                  <li ><b>Palabra: </b> Texto original que puede encontrarse en el texto </li>
                                                  <li ><b>Fonética correcta: </b> La pronunciación que debería tener la palabra en la locución  </li>
                                                  <li ><b>Palabra entera: </b> Valor VERDADERO o FALSO. Indica si la palabra solo puede existir sola o si también puede ser fragmento de una palabra. </li>
                                                  <li ><b>Distingue capitalización</b> Valor VERDADERO o FALSO. Indica si la capitalización de <i>Palabra</i> importa. </li>
                                                  </ul> <br/> A continuación, puede descargar nuestro diccionario base para ver un ejemplo y complementarlo con sus palabras. `}/>
                        </SoftBox>
                        <SoftBox mb={2}>
                          <SoftDropzone name="dictionary" options={{ autoProcessQueue: true, addRemoveLinks: true, acceptedFiles: ".xls, .xlsx, .csv", maxFiles: 1  }} dictionary={{ addHandler: {handleAddDictionary}, setDictionaryText: {setDictionaryText} }}/>
                          <SoftTypography variant="caption" fontWeight="medium" color="secondary">
                            {dictionaryText}
                          </SoftTypography>
                        </SoftBox>
                      </AccordionDetails>
                    </Accordion>
                  </SoftBox>
                </SoftBox>
              </Card>
            </Grid>
            <Grid item xs={12} lg={7}>
              {/* CLONING CARD----------------------------------------------------------------------------- */}
              <Card sx={{ height: "100%" }}>
                <SoftBox padding="1rem">
                  <SoftBox mb={2}>
                    <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Texto a generar
                    </SoftTypography>
                    <SoftTypography component="div" variant="button" color="text" fontWeight="regular">
                      Suba un archivo en formato .txt o pegue el texto directamente en el recuadro.
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftDropzone name="textfile" options={{ autoProcessQueue: true, addRemoveLinks: true, acceptedFiles: "text/plain", maxFiles: 1 }} textZone={{ handler: {handleTextDropped} }}/>
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftInput
                      id="process-text"
                      placeholder="Por favor, ingrese aquí el texto que desea generar"
                      multiline
                      value= {config.processingText}
                      onChange={handleTextChange}
                      rows={6}
                    />
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftButton
                      variant="gradient"
                      color="secondary"
                      onClick={processText}
                    >
                      {"procesar texto"}
                    </SoftButton>
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftInput
                      id="clone-text"
                      placeholder="Texto a generar tras aplicar los cambios del lexicón"
                      multiline
                      value= {config.cloningText}
                      onChange={handleFinalTextChange}
                      rows={6}
                    />
                  </SoftBox>
                  <SoftBox mb={2} width="100%" display="flex" justifyContent="space-between">
                    {/* ONLY ONE OF THESE THREE ELEMENTS WILL BE SHOWN AT A TIME: */}
                    {/* SEND BUTTON */}
                    {!mediaLink && inferenceStatus.toUpperCase() === REQUESTSTATE.UNINITIALIZED &&
                    (<SoftButton
                      variant="gradient"
                      color="secondary"
                      onClick={handleSubmit}
                    >
                      {"enviar"}
                    </SoftButton>
                    )}
                    {/* LOADING ANIMATION */}
                    {(inferenceIsLoading || (!mediaLink && inferenceIsSuccess)) && <Loading />}
                    {/* DOWNLOAD BUTTON */}
                    { mediaLink &&
                      (<SoftBox>
                        <SoftButton
                          width="100px"
                          variant="contained"
                          color="dark"
                          >
                          <Link
                            to={mediaLink}
                            target="_blank"
                            style={{color: "white", textDecoration: "none"}}
                            download
                          >
                                Descargar audio generado
                          </Link>
                        </SoftButton>
                        <IconButton size="medium" aria-label="close" color="inherit" onClick={() => resetDashboard()}>
                          <Icon fontSize="medium">refresh</Icon>
                        </IconButton>
                      </SoftBox>
                      )
                    }
                  </SoftBox>
                </SoftBox>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Default;
