
import * as Yup from "yup";

/**
 * Schema for the user profile creation form.
 */
const userProfileCreationSchema = {
    formID: "new-user-profile-creation-form",
    formFields: {
        firstName: {
            name: "firstName",
            label: "Nombre",
            type: "text",
            placeholder: "Escribe aquí tu nombre",
            errorMsg: "Es necesario completar nombre.",
        },
        lastName: {
            name: "lastName",
            label: "Apellidos",
            type: "text",
            placeholder: "Escribe aquí tus apellidos",
            errorMsg: "Es necesario completar apellidos.",
        },
        company: {
            name: "company",
            label: "Empresa",
            type: "text",
            placeholder: "p.ej. VocalityAI",
            errorMsg: "El nombre de la empresa es necesario.",
        },
        email: {
            name: "email",
            label: "Correo electrónico",
            type: "email",
            placeholder: "Escribe aquí tu correo electrónico",
            errorMsg: "Es necesario completar correo electrónico.",
            invalidMsg: "La dirección no es válida.",
        },
        password: {
            name: "password",
            label: "Contraseña",
            type: "password",
            placeholder: "******",
            errorMsg: "Es necesario indicar contraseña.",
            invalidMsg:
                "La contraseña necesita tener al menos 8 caracteres, una mayúcula y un número.",
        },
        repeatPassword: {
            name: "repeatPassword",
            label: "Repetir contraseña",
            type: "password",
            placeholder: "******",
            errorMsg: "Es necesario indicar contraseña.",
            invalidMsg: "Las contraseñas no son iguales.",
        },
        taxIDType: {
            name: "taxIDType",
            label: "Tipo de documento",
            type: "select",
            placeholder: "Selecciona un tipo de documento",
            options: [
                { value: "es_cif", label: "NIF" },
            ],
            errorMsg: "Es necesario seleccionar un tipo de idenficador fiscal.",
        },
        taxID: {
            name: "taxID",
            label: "Identificador fiscal",
            type: "text",
            placeholder: "Escribe aquí tu identificador fiscal",
            errorMsg: "Es necesario completar el identificador fiscal.",
            invalidMsg: "El identificador fiscal no es válido.",
            pattern: /^((?:(?:[0-9]{8}|[XYZ][0-9]{7})[TRWAGMYFPDXBNJZSQVHLCKE])|[ABCDEFGHJ]-?[0-9]{8})$/i,
        },
        termsOfService: {
            name: "termsOfService",
            label: "Acepto los términos y condiciones",
            type: "checkbox",
            errorMsg: "Es necesario aceptar los términos y condiciones.",
        },
    },
};

// Destructure the form schema
const { formID } = userProfileCreationSchema;
const { formFields } = userProfileCreationSchema;
const { firstName, lastName, email, password, repeatPassword, company, taxIDType, taxID, termsOfService  } = formFields;

const initialUserProfileValues = {
    [firstName.name]: "",
    [lastName.name]: "",
    [email.name]: "",
    [password.name]: "",
    [repeatPassword.name]: "",
    [company.name]: "",
    [termsOfService.name]: false,
    [taxIDType.name]: "es_cif",
    [taxID.name]: "",
};

const userProfileValidations = Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.errorMsg),
    [lastName.name]: Yup.string().required(lastName.errorMsg),
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    [password.name]: Yup.string().required(password.errorMsg).min(8, password.invalidMsg).matches(/^(?=.*[0-9])/, password.invalidMsg).matches(/^(?=.*[A-Z])/, password.invalidMsg),
    [password.name]: Yup.string().required(password.errorMsg).min(8, password.invalidMsg).matches(/^(?=.*[0-9])/, password.invalidMsg).matches(/^(?=.*[A-Z])/, password.invalidMsg),
    [repeatPassword.name]: Yup.string()
      .required(repeatPassword.errorMsg)
      .oneOf([Yup.ref("password"), null], repeatPassword.invalidMsg),
    [company.name]: Yup.string().required(company.errorMsg),
    [taxIDType.name]: Yup.string().required(taxIDType.errorMsg),
    [taxID.name]: Yup.string().required(taxID.errorMsg).matches(taxID.pattern, taxID.invalidMsg),
    [termsOfService.name]: Yup.boolean().oneOf([true], termsOfService.errorMsg),

  })

export { 
    formID,
    formFields,
    initialUserProfileValues,
    userProfileValidations
};
