export const EXCEPTIONMESSAGES = {
    USER_ALREADY_EXISTS: 'Ya existe un usuario registrado con este correo electrónico',
    INVALID_ES_CIF: 'El CIF introducido no es válido',
};

export const STRIPEPAYMENTERRORMESSAGES = {
    card_error: 'Ha ocurrido un error con la tarjeta de crédito',
    validation_error: 'Ha ocurrido un error con la validación de la tarjeta de crédito',
    invalid_request_error: 'Ha ocurrido un error con la solicitud',
};
